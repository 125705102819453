import api from '@/api/general';
import { setBannerCookie, setShortcutCookie } from '@/helpers';

export default {
  state: {
      hasUnity: null,
      unitiesList: null,
      banners: null,
      shortcuts: null,
      homeModal: false,
      selectionStep: 0,
  },

  getters: {
      hasUnity: (state) => state.hasUnity,
      unitiesList: (state) => state.unitiesList,
      banners: (state) => state.banners,
      shortcuts: (state) => state.shortcuts,
      homeModal: (state) => state.homeModal,
      stepSelected: (state) => state.selectionStep,
  },

  actions: {
    async getUnity({ commit }) {
      return api.getUnity().then((response) => {
        let unity = response.data.message;

        commit('SET_HAVE_UNITY', unity );

        Promise.resolve(response);
      })
      .catch( (error) => {
        commit('SET_HAVE_UNITY', null );

        if(error.response.status !== 401 && error.response.status !== 404) Promise.reject(error.response);

        Promise.resolve(error.response);
      });
    },
    async getUnitiesList({ commit }) {
      return api.getUnitiesList()
        .then((response) => {
          let list = [...response.data];

          commit('SAVE_UNITIES_LIST', list);

          return Promise.resolve(response);
        })
        .catch((error) => Promise.reject(error.response));
    },
    async getBanner({ commit }) {
      return api.getBanner()
        .then((response) => {
          setBannerCookie();

          commit('SET_BANNER_LIST', response.data);

          return Promise.resolve(response.data);
        })
        .catch((error) => {
          commit('SET_BANNER_LIST', null);

          Promise.reject(error.response);
        });
    },
    async getShortcuts({ commit }) {
      return api.getShortcuts()
        .then((response) => {
          let list = [...response.data];

          setShortcutCookie();

          commit('SET_SHORTCUT_LIST', list);

          return Promise.resolve(response);
        })
        .catch((error) => {
          commit('SET_SHORTCUT_LIST', null);
          if(error.status !== 401) Promise.reject(error.response);

          return null;
        });
    },
    async resetUnity({ commit }) {
      await commit('SET_HAVE_UNITY', null);
    },
    async setHomeModal({ commit }, value) {
      await commit('SET_HOME_MODAL', value);
    },
    async resetModal({ commit }) {
      await commit('SET_HOME_MODAL', false);
    },
    setSelectionStep({ commit }, step) {
      commit('SET_SELECTION_STEP', step);
    },
    resetSelectionStep({ commit }) {
      commit('SET_SELECTION_STEP', 0);
    }
  },
  mutations: {
    SET_HAVE_UNITY(state, payload) {
      state.hasUnity = payload;
    },
    SAVE_UNITIES_LIST(state, payload) {
      state.unitiesList = payload;
    },
    SET_BANNER_LIST(state, payload) {
      state.banners = payload;
    },
    SET_SHORTCUT_LIST(state, payload) {
      state.shortcuts = payload;
    },
    SET_HOME_MODAL(state, payload) {
      state.homeModal = payload;
    },
    SET_SELECTION_STEP(state, payload) {
      state.selectionStep = payload;
    }
  }
};
