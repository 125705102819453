import Keycloak from '@/api/keycloak.js';
import { setAuthHeader, resetAuthHeader } from '@/config/axios/index';

export default {
  state: {
    userInfo: {
      name: null,
      email: null,
      cpf: null,
      gender: null,
      birthdate: null,
      phone: null,
      userId: null,
      avatar: null,
      zipcode: null,
      files: {},
    },
    authenticated: false,
    userToken: null,
    keycloak: null,
    clientId: process.env.VUE_APP_KEYCLOAK_REDIRECT,
  },

  getters: {
    userData: (state) => state.userInfo,
    userName: (state) =>  state.name,
    userEmail: (state) => state.email,
    userIsLogged: (state) => state.authenticated,
    userToken: (state) => state.userToken,
    userGender: (state) => state.userInfo.gender,
    userZipcode: (state) => state.userInfo.zipcode,
    userFiles: (state) => state.userInfo.files,
    clientId: (state) => state.clientId,
    verifyClientId: (state) => state.clientId === process.env.VUE_APP_KEYCLOAK_CLIENTID,
    //keycloakOnLoadConfig: (state) => state.keycloak &&
    //state.keycloak.loginRequired === undefined ? 'check-sso' : 'login-required',
  },

  actions: {
    async initKeycloak({ commit, dispatch }, onload = '' ){
      try{
        const userInfo = await Keycloak.initKeycloak(onload);

        if (userInfo) {
          commit('KEYCLOAK_INIT', userInfo);

          if(userInfo.authenticated){
            commit('KEYCLOAK_LOGIN', userInfo);
            dispatch('setClientId', process.env.VUE_APP_KEYCLOAK_CLIENTID);
            if(userInfo.gender && userInfo.zipcode) {
              dispatch('getUnity');
            } else {
              dispatch('resetUnity');
            }
          } else {
            dispatch('resetUserData');
          }
        }
      }
      catch (e){
        commit('KEYCLOAK_INIT', null);
      }
    },

    async loginKeycloak({ commit, state }, redirectUri = process.env.VUE_APP_KEYCLOAK_REDIRECT){
      try{
        const userInfo = await state.keycloak.login(
          { redirectUri: redirectUri }
        );

        commit('KEYCLOAK_LOGIN', userInfo);
      }
      catch (e){
        commit('KEYCLOAK_LOGIN', null);
      }
    },

    async setClientId({ commit }, clientId) {
      commit('CLIENT_ID', clientId);
    },

    async registerKeycloak({ state }){
      await state.keycloak.register({
        redirectUri: process.env.VUE_APP_KEYCLOAK_REDIRECT,
      });
    },

    changeKeycloakOnload({ commit }, loginRequired){
      commit('KEYCLOAK_CHANGE_REQUIRED_LOGIN', loginRequired);
    },

    redirectAccount({ state }) {
      return state.keycloak.createLoginUrl({ 'action': 'UPDATE_PROFILE' });
    },

    async userLogout({ commit, dispatch, state }){
      await dispatch('initKeycloak');

      Promise.allSettled([
        // Reset user data
        commit('KEYCLOAK_USER_LOGOUT'),
        // Reset chat data
        dispatch('resetAppointmentData'),
        // Reset upcities play data
        dispatch('resetFinishData'),

        dispatch('resetUnity'),
      ]);

      // Removing authorization from api requests
      resetAuthHeader();

      await state.keycloak?.logout();
    },

    async resetUserData({ commit, dispatch }) {
      Promise.allSettled([
        // Reset user data
        commit('KEYCLOAK_USER_LOGOUT'),
        // Reset chat data
        dispatch('resetAppointmentData'),
        // Reset upcities play data
        dispatch('resetFinishData'),

        dispatch('resetUnity'),
      ]);

      // Removing authorization from api requests
      resetAuthHeader();
    }
  },

  mutations: {
    KEYCLOAK_INIT(state, userInfo){
      state.keycloak = userInfo.keycloak;
    },

    KEYCLOAK_LOGIN(state, userInfo){
      if(userInfo) {
        setAuthHeader(userInfo.userToken);
        state.userInfo.name = userInfo.name;
        state.userInfo.email = userInfo.email;
        state.userInfo.cpf = userInfo.cpf;
        state.userInfo.phone = userInfo.phone;
        state.userInfo.birthdate = userInfo.birthdate;
        state.userInfo.userId = userInfo.userId;
        state.userInfo.avatar = userInfo.avatar;
        state.userInfo.gender = userInfo.gender;
        state.userInfo.zipcode = userInfo.zipcode;
        state.userInfo.files = userInfo.files;
        state.authenticated = userInfo.authenticated;
        state.userToken = userInfo.userToken;
        state.keycloak = userInfo.keycloak;
      }
    },

    KEYCLOAK_USER_LOGOUT(state){
      state.userInfo.name = null;
      state.userInfo.email = null;
      state.userInfo.cpf = null;
      state.userInfo.gender = null;
      state.userInfo.zipcode = null;
      state.userInfo.files = {};
      state.userInfo.birthdate = null;
      state.userInfo.phone = null;
      state.userInfo.userId = null;
      state.userInfo.avatar = null;
      state.authenticated = false;
      state.userToken = null;
    },

    KEYCLOAK_CHANGE_REQUIRED_LOGIN(state, loginRequired){
      state.keycloak.loginRequired = loginRequired;
    },

    KEYCLOAK_UPDATE_USER_TOKEN(state, payload){
      setAuthHeader(payload);
      state.userToken = payload;
    },

    CLIENT_ID(state, payload) {
      state.clientId = payload;
    }
  }
};
