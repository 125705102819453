export default {
  state: {
    userData: null,
    redirectToAttachmentForm: false
  },

  getters: {
    username: (state) => state.userData?.name || '',
    avatar: (state) => state.userData?.avatar || '',
    isDependent: (state) => !!state.userData?.id,
    getRedirectToAttachmentForm: (state) => state.redirectToAttachmentForm
  },

  actions: {
    async fetchUserData({ rootGetters, dispatch, state }, { dependentInfo = null, isCurrentUser = false }) {
      if (isCurrentUser) {
        state.userData = {
          name: rootGetters.userData.name,
          avatar: rootGetters.userData.avatar,
          id: null,
        };

        return;
      }

      if (!dependentInfo) throw new Error('Informação do usuário está inválida.');

      await dispatch('fetchSingleDependents', { id: dependentInfo.id, data: { ...dependentInfo } });
      state.userData = {
        name: dependentInfo.nome,
        avatar: dependentInfo.avatar,
        id: dependentInfo.id,
      };
    },

    setRedirectToAttachmentForm({ commit }, payload) {
      commit('SET_REDIRECT_TO_ATTACHMENT_FORM', payload);
    }
  },

  mutations: {
    SET_REDIRECT_TO_ATTACHMENT_FORM(state, payload) {
      state.redirectToAttachmentForm = payload;
    }
  }
};
