import Vue from 'vue';
import Vuex from 'vuex';
import keycloak from './modules/keycloak';
import appointment from './modules/appointment';
import consult from './modules/consult';
import general from './modules/general';
import survey from './modules/survey';
import autoLogin from './modules/autoLogin';
import relation from './modules/relation';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    keycloak,
    appointment,
    consult,
    general,
    autoLogin,
    relation,
    survey
  },
  plugins: [
    createPersistedState({
      key: 'upMed',
    }),
  ],
});
